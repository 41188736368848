/* global google */
import loadScript from 'load-script';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';

const GG_MAP_URL =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyBrL1tx7TGFeDj8AelqgURlN14YmRZYh4M&v=3.exp';
let mapTarget = null;
let coords = null;
let map = null;
let marker = null;
let loaded = false;

function centerMap() {
  map.setCenter(coords);
}

function initMap() {
  coords = new google.maps.LatLng(
    mapTarget.getAttribute('data-lat'),
    mapTarget.getAttribute('data-long')
  );

  map = new google.maps.Map(mapTarget, {
    center: coords,
    zoom: 17,
    disableDefaultUI: true
  });

  marker = new google.maps.Marker({
    position: coords
  });

  marker.setMap(map);

  eventEmitter.on(EventsTypes.MQ_STATE, centerMap);
}

function load(callback = null, options = {}) {
  if (loaded && typeof callback === 'function') {
    callback();
    return false;
  }

  loadScript(GG_MAP_URL, options, () => {
    loaded = true;
    if (typeof callback === 'function') {
      callback();
    }
  });
}

export default function showMap() {
  mapTarget = document.getElementById('theater-gg-map');
  if (!mapTarget) {
    return false;
  }

  load(initMap);
}
