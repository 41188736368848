import React, { Fragment } from 'react';

import formatDate, { DATE_FORMATS } from 'common/tools/date/format';
import trans from 'common/tools/translations/trans';

import AlertMsg from 'website/components/ui/AlertMsg';
import { OnDateChange } from 'website/containers/showtimes/types';
import { RequestShowtimesData } from 'website/containers/showtimes/utils/types';

type ShowtimesAlertMsgProps = {
  data?: RequestShowtimesData;
  onDateChange: OnDateChange;
};
const ShowtimesAlertMsg = ({ data, onDateChange }: ShowtimesAlertMsgProps) => {
  const msg = (
    <Fragment>
      <span>{trans(data?.message ?? '')}</span>
      {data?.nextDate && (
        <a href="#" onClick={event => onDateChange(data.nextDate, event)}>
          {formatDate(new Date(data.nextDate), DATE_FORMATS.LONG)}
        </a>
      )}
      {data?.messageParam?.day && data.messageParam?.page && (
        <div>
          {trans('showtime.result.title', {
            day: isNaN(Date.parse(data.messageParam.day))
              ? data.messageParam.day
              : formatDate(new Date(data.messageParam.day), DATE_FORMATS.LONG),
            page: data.messageParam.page
          })}
        </div>
      )}
    </Fragment>
  );

  return <AlertMsg className="alert-warning">{msg}</AlertMsg>;
};

export default ShowtimesAlertMsg;
