import { Genre, Maybe } from 'types/graphql-api.generated';

import readAttribute, {
  readBooleanAttribute,
  readNumberAttribute
} from 'common/tools/dom/readAttribute';
import { isString } from 'common/types';

import {
  MovieFromAttributes,
  TheaterFromAttributes
} from 'website/containers/showtimes/types';
import {
  RequestShowtimesData,
  RequestShowtimesFacets
} from 'website/containers/showtimes/utils/types';

import {
  getActiveFilters,
  getUrlHashParams,
  modifyBookingFilter,
  movieHasMultipleVersion
} from '../utils';

type RequestFiltersParams = {
  currentDay?: string;
  movie: Maybe<MovieFromAttributes>;
};

export type RequestFilters = (params: RequestFiltersParams) => Promise<
  RequestShowtimesData & {
    rollerDates?: Maybe<Array<string>>;
    showtimesDates?: Maybe<Array<string>>;
  }
>;

export const initializeCommonData = async (
  anchor: HTMLElement,
  requestFilters?: RequestFilters
) => {
  let movie: Maybe<MovieFromAttributes> = null;

  const movieId = readNumberAttribute(anchor, 'data-movie-id');
  if (movieId) {
    const movieTitle = readAttribute<string, undefined>(
      anchor,
      'data-movie-title'
    );
    const movieGenres = readAttribute<
      Maybe<Array<Maybe<Genre>>> | undefined,
      undefined
    >(anchor, 'data-movie-genres');

    movie = {
      internalId: movieId,
      title: movieTitle,
      genres: window.Array.isArray(movieGenres) ? movieGenres : []
    };
  }

  let theater: Maybe<TheaterFromAttributes> = null;

  const theaterFromAttribute = readAttribute<TheaterFromAttributes, undefined>(
    anchor,
    'data-theater'
  );

  if (theaterFromAttribute && !isString(theaterFromAttribute)) {
    const theaterHasBooking = readBooleanAttribute(
      anchor,
      'data-theater-has-booking'
    );

    theater = {
      ...theaterFromAttribute,
      hasBooking: theaterHasBooking
    };
  }

  const localization = readNumberAttribute(anchor, 'data-localization');

  /* date and page param  */
  let currentDay = readAttribute<string, undefined>(
    anchor,
    'data-selected-date'
  );
  let currentPageNumber = readNumberAttribute(
    anchor,
    'data-current-page-number',
    1
  );

  const currentDateUrl = getUrlHashParams('shwt_date=');
  const currentPageUrl = getUrlHashParams('page=');
  const currentFiltersUrl = getUrlHashParams('filters=');

  if (currentDateUrl) {
    currentDay = currentDateUrl;
  }

  if (currentPageUrl) {
    currentPageNumber = parseInt(currentPageUrl);
  }

  let showtimesFilters = undefined;

  let rollerDates = undefined;
  let showtimesDates = undefined;
  if (requestFilters) {
    const data = await requestFilters({ movie, currentDay });
    showtimesFilters = data?.facets;
    rollerDates = data?.rollerDates;
    showtimesDates = data?.showtimesDates;
  } else {
    showtimesFilters = readAttribute<RequestShowtimesFacets, undefined>(
      anchor,
      'data-showtimes-filters'
    );
    rollerDates = readAttribute<string[], undefined>(
      anchor,
      'data-roller-dates'
    );
    showtimesDates = readAttribute<string[], undefined>(
      anchor,
      'data-showtimes-dates'
    );
  }
  const filtersActivatedByUrl = currentFiltersUrl?.split('+');
  const filters = isString(showtimesFilters)
    ? undefined
    : modifyBookingFilter(showtimesFilters);
  const filtersWithStatus = getActiveFilters(filtersActivatedByUrl, filters);
  const versionDisabled = !movieHasMultipleVersion(
    filtersWithStatus ?? undefined
  );

  return {
    currentDay,
    currentPageNumber,
    filters: filtersWithStatus,
    localization,
    movie,
    rollerDates: !rollerDates || isString(rollerDates) ? [] : rollerDates,
    showtimesDates:
      !showtimesDates || isString(showtimesDates) ? [] : showtimesDates,
    theater,
    versionDisabled
  };
};
