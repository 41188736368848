/**
 * Compare the registered time with current and lock if its past
 * @param {node} el node will display time for showtimes
 * @return {undefined}
 */
const checkTime = el => {
  const showStart = el.getAttribute('data-showtime-time');

  if (showStart) {
    const showStartDate = new Date(showStart);
    const isPast =
      showStartDate.getTime() + showStartDate.getTimezoneOffset() * 60 * 1000 <
      Date.now();

    if (isPast) {
      el.classList.add('past');
    }
  }
};

// lock past showtimes
export default function validateShowtimeTime() {
  const showtimes = document.querySelectorAll('[data-showtime-time]');

  showtimes.forEach(checkTime);
}
