import { useEffect } from 'react';

import { isDev } from 'common/tools/env';

export const useWarnDeprecatedComponent = () => {
  useEffect(() => {
    if (isDev()) {
      // logging is OK in dev env
      // eslint-disable-next-line no-console
      console.warn(
        'Using a depreacted component, use its hook equivalent instead'
      );
    }
  }, []);
};
