import React from 'react';

export const imageFormat = {
  poster: { rw: 160, rh: 231 },
  posterCard: { rw: 120, rh: 160 },
  square: { rw: 70, rh: 70 }
};

type Props = {
  className?: string;
  href?: string;
  label?: string;
  size: keyof typeof imageFormat;
  src: string;
  title: string;
};

const Thumbnail = ({ className, href, label, size, src, title }: Props) => {
  const imgWidth = imageFormat[size].rw;
  const imgHeight = imageFormat[size].rh;

  const imgSrc = (
    <img
      alt={title}
      className="thumbnail-img"
      src={src}
      width={imgWidth}
      height={imgHeight}
    />
  );

  const imgLabel = label ? <div className={className}>{label}</div> : null;

  return (
    <figure className="thumbnail">
      {href ? (
        <a
          className="thumbnail-container thumbnail-link"
          title={title}
          href={href}
        >
          {imgSrc}
          {imgLabel}
        </a>
      ) : (
        <span className="thumbnail-container thumbnail-link">
          {imgSrc}
          {imgLabel}
        </span>
      )}
    </figure>
  );
};

export default Thumbnail;
