import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { ShowtimeVersion } from 'types/graphql-api.generated';

import Dropdown from 'common/components/generic/Dropdown';
import { TheaterReservationTracking } from 'common/constants/trackingEventsNames';
import formatDate, { DATE_FORMATS } from 'common/tools/date/format';
import readAttribute from 'common/tools/dom/readAttribute';
import { isString } from 'common/types';

import ShowtimesVersionHolder from 'website/components/showtimes/ShowtimesVersionHolder';
import {
  MovieFromTheaterPage,
  RequestTheaterComingSoonData,
  TheaterFromAttributes
} from 'website/containers/showtimes/types';
import { movieForTracking } from 'website/containers/showtimes/utils';
import standalone from 'website/containers/showtimes/utils/standalone';
import {
  RouteParam,
  ShowtimesByVersions
} from 'website/containers/showtimes/utils/types';
import {
  useDateChange,
  useFetchShowtimes
} from 'website/containers/showtimes/utils/useShowtimesHooks';
import { openShowtimeModal } from 'website/services/modal';

type ShowtimesUiProps = {
  anchor: Element;
  movie?: MovieFromTheaterPage;
  showtimeDates: string[];
  showtimes: ShowtimesByVersions<ShowtimeVersion>;
  theater: TheaterFromAttributes;
  theaterHasBooking: boolean;
};

interface TheaterComingSoonRouteParams {
  idTheater?: string;
  idMovie?: number;
  date?: string;
}

/* GLOBAL VARS */
let anchorsParent;
const SHOWTIME_API_ROUTE = 'internal_api_theater_movie_showtimes';

/* UI FINAL RENDER COMPONENT */
const ShowtimesUi = ({
  anchor,
  movie,
  showtimeDates,
  showtimes: propShowtime,
  theater,
  theaterHasBooking
}: ShowtimesUiProps) => {
  const [showtimes, setShowtimes] = useState(propShowtime);
  const [alternateRouteParam, setAlternateRouteParam] = useState<
    TheaterComingSoonRouteParams & Pick<RouteParam, 'day' | 'page'>
  >({});

  const validatingQuery = (routeParam: TheaterComingSoonRouteParams) =>
    !!routeParam.idTheater && !!routeParam.idMovie && !!routeParam.date;

  const { data, loading, setRouteParam } = useFetchShowtimes<
    RequestTheaterComingSoonData,
    Record<string, any>,
    TheaterComingSoonRouteParams
  >(
    anchor,
    {},
    {
      idTheater: theater?.internalId ?? undefined,
      idMovie: movie?.internalId ?? undefined,
      date: showtimeDates[0]
    },
    SHOWTIME_API_ROUTE,
    validatingQuery
  );

  useEffect(() => {
    if (alternateRouteParam) {
      setRouteParam(prevParam => ({
        date: alternateRouteParam.day,
        idMovie: prevParam.idMovie,
        idTheater: prevParam.idTheater
      }));
    }
  }, [alternateRouteParam, setRouteParam]);

  const { selectedDate, handleDateChange } =
    useDateChange<TheaterComingSoonRouteParams>(
      showtimeDates[0],
      [],
      setAlternateRouteParam
    );

  useEffect(() => {
    if (data?.showtimes) setShowtimes(data.showtimes);
  }, [data?.showtimes]);

  // INTERMEDIATE COMPONENT RENDER
  const renderDropdown = () => {
    const options = showtimeDates.map(date => (
      <option key={`${movie?.internalId}_${date}`} value={date}>
        {formatDate(new Date(date), {
          timeZone: 'UTC',
          ...DATE_FORMATS.LONG
        })}
      </option>
    ));

    return (
      <Dropdown
        onChange={event => handleDateChange(event.target.value)}
        value={selectedDate}
      >
        {options}
      </Dropdown>
    );
  };

  const renderShowtimes = () => {
    const additionalClassName = loading ? 'loading' : null;

    const trackingObj: Omit<TheaterReservationTracking, 'showtime'> = {
      movie: movieForTracking(movie),
      theater
    };

    return (
      movie &&
      theater && (
        <ShowtimesVersionHolder
          openModal={(showtimeId, fallback, trackingContext) =>
            openShowtimeModal({
              fallback,
              showtimeId,
              trackingContext
            })
          }
          additionalClassName={additionalClassName}
          hideDate
          showtimes={showtimes}
          theaterHasBooking={theaterHasBooking}
          tracking={trackingObj}
        />
      )
    );
  };

  // FINAL UI COMPONENT RENDER

  return movie ? (
    <>
      {renderDropdown()}
      {renderShowtimes()}
    </>
  ) : null;
};

/* EXECUTION */
const init = async () => {
  anchorsParent = document.getElementById(
    'theaterpage-showtimes-coming-soon-ui'
  );
  if (!anchorsParent) {
    return;
  }

  const theater = readAttribute<TheaterFromAttributes, undefined>(
    anchorsParent,
    'data-theater'
  );

  if (!theater || isString(theater)) {
    return;
  }

  const anchors = anchorsParent.getElementsByClassName('showtimes-anchor');

  for (const item of anchors) {
    const anchorDataHolder = item.getElementsByClassName('dropdown-select')[0];

    if (anchorDataHolder) {
      const movie = readAttribute<MovieFromTheaterPage, undefined>(
        anchorDataHolder,
        'data-movie'
      );
      const showtimes = readAttribute<
        ShowtimesByVersions<ShowtimeVersion>,
        undefined
      >(anchorDataHolder, 'data-showtimes');
      const showtimeDates = readAttribute<string[], undefined>(
        anchorDataHolder,
        'data-dates'
      );
      const theaterHasBooking = readAttribute<boolean | number, undefined>(
        anchorDataHolder,
        'data-theater-has-booking'
      );

      const root = createRoot(item);
      root.render(
        <ShowtimesUi
          anchor={item}
          movie={isString(movie) ? undefined : movie}
          theater={theater}
          showtimeDates={
            !showtimeDates || isString(showtimeDates) ? [] : showtimeDates
          }
          showtimes={
            !showtimes || isString(showtimes)
              ? { dubbed: [], local: [], original: [] }
              : showtimes
          }
          theaterHasBooking={
            isString(theaterHasBooking) ? false : !!theaterHasBooking
          }
        />
      );
    } else {
      standalone(item);
    }
  }
};

export default init;
