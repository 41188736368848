import classNames from 'classnames';
import React from 'react';

import { ShowtimeVersion } from 'types/graphql-api.generated';

import { TheaterReservationTracking } from 'common/constants/trackingEventsNames';
import getVersionLabel from 'common/tools/showtime/getVersionLabel';
import trans from 'common/tools/translations/trans';

import {
  PartialShowtime,
  ShowtimesByVersions
} from 'website/containers/showtimes/utils/types';

import ShowtimesVersion, { OpenModalShowtimes } from './ShowtimesVersion';

type BrandedShowtimes<T extends ShowtimeVersion> = {
  showtimesVersion: T;
  showtimesLabel: string;
  showtimesHours: PartialShowtime[];
};

type ShowtimesVersionHolderProps = {
  additionalClassName?: string | null;
  hideDate?: boolean;
  openModal?: OpenModalShowtimes;
  showtimes: ShowtimesByVersions<ShowtimeVersion>;
  theaterHasBooking?: boolean | null;
  tracking: Omit<TheaterReservationTracking, 'showtime'>;
};

const setShowtimesByBrand = (
  showtimes: ShowtimesByVersions<ShowtimeVersion>
): BrandedShowtimes<ShowtimeVersion>[] => [
  {
    showtimesVersion: ShowtimeVersion.Dubbed,
    showtimesLabel: getVersionLabel(ShowtimeVersion.Dubbed),
    showtimesHours: showtimes.dubbed
  },
  {
    showtimesVersion: ShowtimeVersion.Local,
    showtimesLabel: getVersionLabel(ShowtimeVersion.Local),
    showtimesHours: showtimes.local
  },
  {
    showtimesVersion: ShowtimeVersion.Original,
    showtimesLabel: getVersionLabel(ShowtimeVersion.Original),
    showtimesHours: showtimes.original
  }
];

const ShowtimesVersionHolder = ({
  additionalClassName,
  hideDate,
  openModal,
  showtimes,
  theaterHasBooking,
  tracking
}: ShowtimesVersionHolderProps) => {
  const showtimesByBrand = setShowtimesByBrand(showtimes);
  const showtimesVersions = (
    <>
      {showtimesByBrand.map(
        ({ showtimesVersion, showtimesLabel, showtimesHours }) => (
          <ShowtimesVersion
            hideDate={hideDate}
            key={showtimesVersion}
            openModal={openModal}
            showtimesHours={showtimesHours}
            showtimesLabel={showtimesLabel}
            tracking={tracking}
          />
        )
      )}

      {theaterHasBooking && (
        <p className="e-ticket icon icon-ticket">
          <span className="e-ticket-text">
            {trans('moviepage_showtimes.e-ticket.text')}
          </span>
        </p>
      )}
    </>
  );

  const finalClassName = classNames(
    'showtimes-versions-holder',
    additionalClassName
  );

  return <div className={finalClassName}>{showtimesVersions}</div>;
};

export default ShowtimesVersionHolder;
