import React from 'react';

import useMqState from 'common/react/hooks/useMqState';
import getDisplayName from 'common/tools/react/getDisplayName';
import { useWarnDeprecatedComponent } from 'common/tools/react/warn-deprecated';

/**
 *  This HOC will pass the current MQ state to its wrapped component
 *  Compared to calling global getMQState() right away, we will benefit from
 *  being up to date if user resizes or changes the MQ state by any mean
 *
 *  @param {Component} WrappedComponent A component to enhance
 *  @returns {Component} a enhanced component
 */
const withMQState = WrappedComponent => {
  const WithMQState = props => {
    useWarnDeprecatedComponent();
    const mqState = useMqState();
    return <WrappedComponent mqState={mqState} {...props} />;
  };
  WithMQState.displayName = `withMQState(${getDisplayName(WrappedComponent)})`;
  return WithMQState;
};

export default withMQState;
