import { useEffect, useState } from 'react';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { getStateIndex } from 'common/tools/dom/mq-state';

const useMqState = () => {
  const [mqState, setMqState] = useState<number>(getStateIndex());

  useEffect(() => {
    eventEmitter.on(EventsTypes.MQ_STATE, setMqState);
    return () => {
      eventEmitter.removeListener(EventsTypes.MQ_STATE, setMqState);
    };
  }, []);

  return mqState;
};

export default useMqState;
