import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const AlertMsg = props => {
  const classes = classNames('alert', props.className);

  return (
    <div className={classes}>
      <div className="alert-content">{props.children}</div>
    </div>
  );
};

AlertMsg.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default AlertMsg;
