import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { off, on } from 'common/tools/dom/events';
import { isHTMLElement } from 'common/types';

type FilterDropdownProps<T> = {
  items?: T;
  children?: (items?: T) => React.ReactNode;
  computeExtraProps: () => {
    activeItemCount: number;
    firstItemLabel?: string;
    noItems?: boolean;
  };
  nullLabel?: string;
  itemLength?: number;
};

const FilterDropdown = <T,>({
  nullLabel,
  children,
  items,
  computeExtraProps,
  itemLength = 0
}: FilterDropdownProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleDocumentClick = (e: Event) => {
    let element = e.target;
    const { current } = dropdownRef;
    while (element && element !== document && isHTMLElement(element)) {
      if (element === current) {
        return; // user clicked inside drop down
      }
      element = element.parentNode;
    }
    setOpen(false);
  };

  useEffect(() => {
    on(document, 'click', handleDocumentClick);
    return () => {
      off(document, 'click', handleDocumentClick);
    };
  }, []);

  const handleClick = () => {
    setOpen(previousState => {
      return !previousState;
    });
  };

  const extraProps = computeExtraProps();

  const showtimeFilterContainerClasses = classNames(
    'showtime-filter-container',
    {
      open // show drop down list
    }
  );

  const showtimeFilterActionClasses = classNames('showtime-filter-action', {
    active: extraProps.activeItemCount > 0, // turning green the drop-down
    off: extraProps.noItems // gray drop down and lock actions
  });

  const label =
    extraProps.activeItemCount === 1 ? extraProps.firstItemLabel : nullLabel;

  const labelCount =
    extraProps.activeItemCount > 1 ? ` (${extraProps.activeItemCount})` : '';

  return (
    <div className={showtimeFilterContainerClasses} ref={dropdownRef}>
      <div className={showtimeFilterActionClasses} onClick={handleClick}>
        {label}
        {labelCount}
      </div>
      <div
        className={classNames(
          'showtime-filter-dropdown',
          `size-${itemLength >= 3 ? 3 : itemLength}`
        )}
      >
        {children?.(items)}
      </div>
    </div>
  );
};

export default FilterDropdown;
