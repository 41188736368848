import classNames from 'classnames';
import React, { Fragment, ReactNode } from 'react';

import { Maybe, ReleaseTagType, Tag } from 'types/graphql-api.generated';

import UserRating from 'common/components/UserRating';
import {
  BRAND,
  IMAGE_EMPTY_PHOTO_PORTRAIT
} from 'common/configuration/constants';
import formatDate, { DATE_FORMATS } from 'common/tools/date/format';
import { path } from 'common/tools/network/routing';
import { getImageUrl } from 'common/tools/network/url';
import getRuntime from 'common/tools/showtime/getRuntime';
import striptags from 'common/tools/string/striptags';
import trans from 'common/tools/translations/trans';

import Thumbnail from 'website/components/ui/Thumbnail';
import {
  MovieFromRequest,
  PersonFromRequest
} from 'website/containers/showtimes/types';

const withTrans = trans('moviepage_movie_details.with');
const fromTrans = trans('moviepage_movie_details.director');

interface MovieCardProps {
  movie: Maybe<MovieFromRequest>;
  children?: ReactNode;
  enablePersonsInMovieCard: boolean;
  enableKidsLabelInMovieCard: boolean;
  enableRatingInMovieCard?: boolean;
}

const handleRating = (
  type: string,
  rating: number | undefined,
  movie: MovieFromRequest
) => {
  let typeName;
  let typePath;

  switch (type) {
    case 'press':
      typeName = trans('moviepage_movie_details.press');
      typePath = path('moviepage_reviews_press', {
        movie: movie.internalId ?? undefined
      });
      break;
    case 'editorial':
      typeName = BRAND;
      typePath = path('moviepage_reviews_editorial', {
        movie: movie.internalId ?? undefined
      });
      break;
    default:
      typeName = trans('moviepage_movie_details.public');
      typePath = path('moviepage_reviews_public', {
        movie: movie.internalId ?? undefined
      });
  }

  const ratingTitleOutput = typePath ? (
    <a className="rating-title" href={typePath}>
      {typeName}
    </a>
  ) : (
    <span className="rating-title">{typeName}</span>
  );

  return (
    <div className="rating-item">
      <div className="rating-item-content">
        {ratingTitleOutput}
        <div className="stareval stareval-medium">
          <UserRating value={rating} theme="yellow" readOnly />
          <span className="stareval-note">{rating?.toFixed(1)}</span>
        </div>
      </div>
    </div>
  );
};

const handlePersonName = (person: PersonFromRequest) => {
  if (!person.firstName && !person.lastName) {
    return false;
  }

  let personName = person.firstName;

  if (person.firstName && person.lastName) {
    personName = person.firstName + ' ' + person.lastName;
  }

  if (!person.firstName && person.lastName) {
    personName = person.lastName;
  }

  return personName;
};

const handleComa = (index: number, array: any[]) =>
  index === array.length - 1 ? '' : ', ';

const handleAgeTag = (
  movie: MovieFromRequest
): false | null | Pick<Tag, 'name' | 'internalId'> => {
  if (!movie.relatedTags) {
    return false;
  }

  let ageTag = null;

  movie.relatedTags.forEach(tag => {
    if (tag.scope === 'AGE') {
      ageTag = { name: tag.name, internalId: tag.internalId };
      return;
    }
  });

  return ageTag;
};

const MovieCard = ({
  movie,
  children,
  enablePersonsInMovieCard,
  enableKidsLabelInMovieCard,
  enableRatingInMovieCard
}: MovieCardProps) => {
  if (!movie) return null;

  const movieTitle = movie.title;
  const movieImg = getImageUrl({
    src: movie.poster?.path ?? IMAGE_EMPTY_PHOTO_PORTRAIT,
    format: { rw: 200, rh: 283 }
  });
  let moviePath = null;
  let movieLabel = null;
  let movieLabelClass = null;
  const movieAgeTag = handleAgeTag(movie);

  if (path('moviepage', { movie: movie.internalId ?? undefined })) {
    moviePath = path('moviepage', { movie: movie.internalId ?? undefined });
  }

  if (movie.customFlags?.isPremiere) {
    movieLabel = trans('theater.premiere_release');
    movieLabelClass = 'label-premiere-full';
  }

  if (movie.customFlags?.weeklyOuting) {
    movieLabel = trans('theater.week_release');
    movieLabelClass = 'label-primary-full';
  }

  const movieLabelClasses = classNames(
    'label label-text label-sm label-status',
    movieLabelClass
  );

  const movieReleaseDateInfo =
    movie.releases?.find(item => {
      return (
        item?.releaseTags?.tagTypes?.includes(ReleaseTagType.Released) ||
        item?.releaseTags?.tagTypes?.includes(ReleaseTagType.Theater)
      );
    }) ?? null;

  const movieReleaseDate = movieReleaseDateInfo?.releaseDate?.date ?? null;

  const movieThumbnail = (
    <Thumbnail
      className={movieLabelClasses}
      href={moviePath ?? undefined}
      label={movieLabel ?? undefined}
      size="poster"
      src={movieImg}
      title={movieTitle ?? ''}
    />
  );

  const spacer = <span className="spacer">&nbsp;|&nbsp;</span>;

  const movieRuntime = getRuntime(movie.runtime);

  const movieInfo = (
    <div className="meta-body-item meta-body-info">
      {movieReleaseDate && (
        <Fragment>
          {formatDate(new Date(movieReleaseDate), DATE_FORMATS.LONG)}
        </Fragment>
      )}

      {movieReleaseDate && movieRuntime && spacer}

      {movieRuntime && <Fragment>{movieRuntime}</Fragment>}

      {(movieRuntime || movieReleaseDate) &&
        movie.genres?.length !== 0 &&
        spacer}

      {movie.genres &&
        movie.genres.length !== 0 &&
        movie.genres.slice(0, 3).map((item, index, array) => {
          if (!item.translate || !item.id) {
            return false;
          }

          const outputGenre = path('movies_all') ? (
            <a href={path('movies_all', { genre: item.id })}>
              {item.translate}
            </a>
          ) : (
            <span>{item.translate}</span>
          );

          return (
            <Fragment key={item.id}>
              {outputGenre}
              {handleComa(index, array)}
            </Fragment>
          );
        })}

      {movie.genres?.length !== 0 && movie.countries?.length !== 0 && spacer}

      {movie.countries &&
        movie.countries.length !== 0 &&
        movie.countries.map((item, index, array) => {
          if (!item?.localizedName || !item.id) {
            return false;
          }

          const outputCountry = path('movies_all') ? (
            <a
              className="nationality"
              href={path('movies_all', { country: item.id })}
            >
              {item.localizedName}
            </a>
          ) : (
            <span>{item.localizedName}</span>
          );

          return (
            <Fragment key={item.id}>
              {outputCountry}
              {handleComa(index, array)}
            </Fragment>
          );
        })}
    </div>
  );

  const movieCredits =
    enablePersonsInMovieCard && movie.credits && movie.credits.length ? (
      <div className="meta-body-item meta-body-direction light">
        {`${fromTrans} `}
        {movie.credits.slice(0, 3).map((item, index, array) => {
          if (!item?.person) {
            return;
          }

          const personName = handlePersonName(item.person);

          if (
            item.person.seo?.browsable &&
            path('person', { person: item.person.internalId ?? undefined })
          ) {
            return (
              <Fragment key={`${personName}-${index}`}>
                <a
                  className="dark-grey-link"
                  href={path('person', {
                    person: item.person.internalId ?? undefined
                  })}
                >
                  {personName}
                </a>
                {handleComa(index, array)}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={`${personName}-${index}`}>
                <span className="isnt-clickable">{personName}</span>
                {handleComa(index, array)}
              </Fragment>
            );
          }
        })}
      </div>
    ) : null;

  const movieCasting =
    enablePersonsInMovieCard &&
    movie.cast &&
    movie.cast.nodes &&
    movie.cast.nodes.length ? (
      <div className="meta-body-item meta-body-actor light">
        {`${withTrans} `}
        {movie.cast.nodes.slice(0, 3).map((item, index, array) => {
          let person = null;

          if (item?.actor) {
            person = item.actor;
          }

          if (item?.voiceActor) {
            person = item.voiceActor;
          }

          if (item?.originalVoiceActor) {
            person = item.originalVoiceActor;
          }

          if (!person) {
            return;
          }

          const personName = handlePersonName(person);

          if (
            person.seo?.browsable &&
            path('person', { person: person.internalId ?? undefined })
          ) {
            return (
              <Fragment key={`${personName}-${index}`}>
                <a
                  className="dark-grey-link"
                  href={path('person', {
                    person: person.internalId ?? undefined
                  })}
                >
                  {personName}
                </a>
                {handleComa(index, array)}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={`${personName}-${index}`}>
                <span className="isnt-clickable">{personName}</span>
                {handleComa(index, array)}
              </Fragment>
            );
          }
        })}
      </div>
    ) : null;

  const certificateLabel = movie.releases?.[0]?.certificate?.label;
  const certificate = certificateLabel && (
    <div className="certificate">
      <span className="certificate-text">{certificateLabel}</span>
    </div>
  );

  const kidsLabel = enableKidsLabelInMovieCard && movieAgeTag && (
    <div className="label-holder">
      <div className="label kids-label aged-default">{movieAgeTag.name}</div>
    </div>
  );

  const synopsisData =
    movie.synopsis ||
    (movie.synopsisFull
      ? `${striptags(movie.synopsisFull).slice(0, 350)} ...`
      : null);

  const synopsis = synopsisData && (
    <div className="synopsis">
      <div className="content-txt">{synopsisData}</div>
    </div>
  );

  const rating = enableRatingInMovieCard && (
    <div className="rating-holder rating-holder-2">
      {movie.stats &&
        movie.stats.pressReview &&
        movie.stats.pressReview.count &&
        movie.stats.pressReview.score &&
        handleRating('press', movie.stats.pressReview.score, movie)}

      {movie.stats &&
        movie.stats.userRating &&
        movie.stats.userRating.count &&
        movie.stats.userRating.score &&
        movieReleaseDate &&
        new Date(movieReleaseDate).getTime() <= Date.now() &&
        handleRating('user', movie.stats.userRating.score, movie)}

      {movie.editorialReviews &&
        movie.editorialReviews[0] &&
        movie.editorialReviews[0].rating &&
        handleRating('editorial', movie.editorialReviews[0].rating, movie)}
    </div>
  );

  const id = `movie${movie.internalId}`;
  const className =
    'card entity-card entity-card-list movie-card-theater cf hred';

  const movieTilteOutput = moviePath ? (
    <a className="meta-title-link" href={moviePath}>
      {movieTitle}
    </a>
  ) : (
    <span className="meta-title-link">{movieTitle}</span>
  );

  return (
    <div id={id} className={className}>
      {movieThumbnail}

      <div className="meta">
        <h2 className="meta-title">{movieTilteOutput}</h2>

        <div className="meta-body">
          {movieInfo}
          {movieCredits}
          {movieCasting}
          {certificate}
          {kidsLabel}
          {rating}
        </div>
      </div>
      {synopsis}
      {children}
    </div>
  );
};

export default MovieCard;
