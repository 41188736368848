import React, { useEffect, useRef } from 'react';

import { Maybe } from 'types/graphql-api.generated';

import { RollerDateItemClickTracking } from 'common/constants/trackingEventsNames';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';

import Roller from 'website/components/ui/Roller';

import DateItem from './DateItem';

type RollerDateProps = {
  dates?: Maybe<string[]>;
  selectedDate?: string;
  disabledDates?: string[] | null;
  onClick: (index: number) => void;
  tracking: Omit<RollerDateItemClickTracking, 'date'>;
};

const RollerDate = ({
  disabledDates = null,
  selectedDate,
  dates,
  onClick,
  tracking
}: RollerDateProps) => {
  const rollerDate = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    eventEmitter.emit(EventsTypes.ROLLER_GETCURRENT_ITEM, rollerDate.current);
  }, [selectedDate]);

  const dateItems = dates?.map((date, index) => (
    <DateItem
      key={date}
      date={date}
      onClick={onClick.bind(null, index)}
      current={selectedDate === date}
      disabled={disabledDates ? disabledDates.indexOf(date) !== -1 : false}
      tracking={tracking}
    />
  ));

  return (
    <Roller
      id="calendar-date-roller"
      className="calendar-date-roller"
      offset={1}
      ref={rollerDate}
    >
      {dateItems}
    </Roller>
  );
};

export default RollerDate;
