import isNumeric from 'common/tools/string/isNumeric';
import { isString } from 'common/types';

/**
 * Function to get the runtime in a readable format.
 * @param runtime - The runtime value which can be a string or a number.
 * @returns The runtime in 'h min' format if it's a string or a number, otherwise null.
 */
const getRuntime = (runtime?: string | number | null) => {
  // Check if runtime is a string and not equal to '0h 00min'
  if (isString(runtime) && runtime !== '0h 00min') {
    // Replace the string format from 'PTHMS' to 'h min'
    return runtime.replace(/PT(\d+)H(\d+)M(\d+)S/u, '$1h $2min');
  }
  // Check if runtime is a number
  else if (isNumeric(runtime)) {
    // Convert the number to hours and minutes
    const runtimeDate = new Date(runtime * 1000);
    return `${runtimeDate.getUTCHours()}h ${runtimeDate.getUTCMinutes()}min`;
  }
  // If runtime is neither a string nor a number, return null
  else {
    return null;
  }
};

export default getRuntime;
