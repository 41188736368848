import classNames from 'classnames';
import React, { memo, MouseEventHandler, useMemo } from 'react';

import {
  RollerDateItemClickTracking,
  TrackingEventNames
} from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import formatDate from 'common/tools/date/format';

type DateItemProps = {
  current: boolean;
  date: string;
  disabled?: boolean;
  onClick: () => void;
  tracking: Omit<RollerDateItemClickTracking, 'date'>;
};

const DateItem = ({
  current,
  date,
  disabled = false,
  onClick,
  tracking
}: DateItemProps) => {
  const handleTracking = () => {
    eventEmitter.emit(
      TrackingEventNames.TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK,
      { ...tracking, date }
    );
  };
  const handleClick: MouseEventHandler<HTMLSpanElement> = event => {
    event.preventDefault();
    if (!disabled) {
      onClick();
      handleTracking();
    }
  };

  const dateObject = useMemo(() => new Date(date), [date]);

  const weekday = formatDate(dateObject, {
    weekday: 'short',
    timeZone: 'UTC'
  });
  const monthNumber = formatDate(dateObject, {
    day: 'numeric',
    timeZone: 'UTC'
  });
  const monthName = formatDate(dateObject, {
    month: 'short',
    timeZone: 'UTC'
  });

  const classes = classNames(
    'calendar-date-link roller-item',
    { current },
    { disabled }
  );

  return (
    <span className={classes} onClick={handleClick}>
      <div className="day">{weekday}</div>
      <div className="num">{monthNumber}</div>
      <div className="month">{monthName}</div>
    </span>
  );
};

export default memo(
  DateItem,
  (props, nextProps) =>
    props.date === nextProps.date && props.current === nextProps.current
);
