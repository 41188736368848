import React from 'react';
import { createRoot } from 'react-dom/client';
import { connect } from 'react-redux';

import { SocialActionPlacements } from 'common/constants/trackingEventsNames';
import readAttribute, {
  readBooleanAttribute
} from 'common/tools/dom/readAttribute';
import { isString, ReduxUser } from 'common/types';

import FavoriteTheaterButton from 'website/components/user/FavoriteTheaterButton';
import allocineContainer from 'website/containers/allocineContainer';

type Theater = { id: string; name: string };

export default () => {
  const anchors = document.getElementsByClassName('add-theater-anchor');

  const mapStateToProps = (state: { user: ReduxUser }) => ({
    user: state.user
  });

  const ConnectedFavoriteTheaterButton = allocineContainer(
    connect(mapStateToProps)(FavoriteTheaterButton)
  );

  for (let i = 0; i < anchors.length; i++) {
    const theater = readAttribute<Theater, undefined>(
      anchors[i],
      'data-theater'
    );
    const iconOnly = readBooleanAttribute(anchors[i], 'data-icon-only');
    const classes = readAttribute<string>(anchors[i], 'data-classes');
    const trackingContext = readAttribute<SocialActionPlacements>(
      anchors[i],
      'data-tracking-action'
    ) as SocialActionPlacements | undefined;

    if (theater && !isString(theater)) {
      const root = createRoot(anchors[i]);
      root.render(
        <ConnectedFavoriteTheaterButton
          iconOnly={iconOnly}
          theaterId={theater.id}
          theaterName={theater.name}
          classes={classes}
          trackingContext={trackingContext}
        />
      );
    }
  }
};
