import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import readAttribute from 'common/tools/dom/readAttribute';
import debounce from 'common/tools/functions/debounce';
import positionTooltip, { Position } from 'common/tools/tooltip/positioner';
import trans from 'common/tools/translations/trans';

type DataTooltip = {
  content?: string;
  event?: string;
  position?: string;
};

interface HTMLTooltipAnchorElement extends HTMLDivElement {
  tooltipObj: Partial<
    Omit<DataTooltip, 'position'> & {
      ga?: Record<string, any> | null;
      hoverOnce?: boolean;
      id: number;
      jan?: Record<string, string>;
      position?: Position | 'auto';
      skin?: string;
      url?: string;
    }
  >;
}

interface HTMLTooltipElement extends HTMLDivElement {
  anchor?: HTMLTooltipAnchorElement | null;
}

// CONSTANTS
// ---------
const MOUSE_EVENT_OVER = 'mouseover';
const MOUSE_EVENT_OUT = 'mouseout';
const TOOLTIP_CLASSNAME = 'tooltip tooltip-default';

// DOM Access
// ----------
// tooltip parent container
let elTooltip: HTMLTooltipElement | null = null;
// tooltip content
let elTooltipContainer: HTMLDivElement | null = null;
// tooltip injected content
let elTooltipSpan: HTMLSpanElement | null = null;
// tooltip arrow
let elTooltipArrow: HTMLDivElement | null = null;
// tooltip link
let elTooltipLink: HTMLAnchorElement | null = null;
// home page layout
const elContentLayout = document.getElementById('content-layout');
// header layout
const elMainHeader = document.getElementById('header-main');

// Settings
// --------
// the timer id that will hide the tooltip
let timeout = 0;
// defines the expected event live that displays the tooltip
let tooltipEvent = MOUSE_EVENT_OVER;
// escapes identical display reminders
let isShowing = false;
// escapes the tooltip display update for the same id
let currentId: number | null | undefined = null;
// delay before hiding tooltip after a mouse out event
const tooltipDelay = 500; // ms

// compute and set position to the tooltip
const setTooltipPosition = (
  anchor: HTMLDivElement,
  position: Position | 'auto' | undefined = 'auto',
  skin = ''
) => {
  // reset and display tooltip to get size before positioning
  // display tooltip
  if (elTooltip) {
    elTooltip.className = TOOLTIP_CLASSNAME;
    // apply skin if exist
    if (skin) {
      elTooltip.classList.add(skin);
    }
    // reset js style injection
    elTooltip.style.width = 'auto';
    elTooltip.style.height = 'auto';

    if (elTooltipArrow && elTooltipContainer && elContentLayout) {
      positionTooltip(
        position,
        anchor,
        elTooltip,
        elTooltipArrow,
        elTooltipContainer,
        elContentLayout,
        elMainHeader?.getBoundingClientRect()?.height
      );
    }
  }
};

// send ga tracking if exist
const trackTooltip = () => {
  if (elTooltip && elTooltip.anchor) {
    const {
      anchor: {
        tooltipObj: { ga, jan }
      }
    } = elTooltip;
    if (ga) {
      eventEmitter.emit(TrackingEventNames.ON_CLICK_THEATER_TOOLTIP, {
        tracking: ga,
        jan
      });
    }
  }
};

// display the tooltip
const showTooltip = () => {
  // stop the current delay to hide tooltip
  window.clearTimeout(timeout);

  if (elTooltip && elTooltip.anchor) {
    const {
      anchor,
      anchor: {
        tooltipObj: { content, id, position, skin, url }
      }
    } = elTooltip;

    // if its new anchor
    if (id !== currentId) {
      currentId = id;
      // unlock showTooltip
      isShowing = false;

      // update the tooltip content
      if (elTooltipSpan && content) elTooltipSpan.innerHTML = content;

      if (url) {
        elTooltipLink?.setAttribute('href', url);
        if (elTooltipLink) elTooltipContainer?.appendChild(elTooltipLink);
      } else if (elTooltipLink) {
        if (elTooltipLink) elTooltipContainer?.removeChild(elTooltipLink);
      }
    }

    // escape every next call if no changes
    if (isShowing) {
      return;
    }
    // lock showTooltip
    isShowing = true;
    // set position and display the tooltip
    setTooltipPosition(anchor, position, skin);
  }
};

// hide the tooltip (no delay)
const hideTooltip = () => {
  // stop the current delay to hide tooltip
  window.clearTimeout(timeout);
  // hide tooltip and reset css classname
  if (elTooltip) elTooltip.className = `${TOOLTIP_CLASSNAME} tooltip-hide`;
  // unlock showTooltip
  isShowing = false;
};

// start a delay to hide tooltip
const timeoutHideTooltip = () => {
  // stop the current delay to hide tooltip
  window.clearTimeout(timeout);
  // start a new delay to hide tooltip
  timeout = window.setTimeout(hideTooltip, tooltipDelay);
};

// force to update the tooltip position and size
const positionUpdate = () => {
  // if showTooltip is locked,
  // that means a tooltip is display
  if (isShowing) {
    // unlock showTooltip
    isShowing = false;
    // update tooltip position
    showTooltip();
    // restart the delay to hide tooltip
    timeoutHideTooltip();
  }
};

// callback when the mouseover event is trigger on the tooltip (not the anchor)
const tooltipOver = () => {
  // if the display tooltip is by a mouseover event on the anchor
  if (tooltipEvent === MOUSE_EVENT_OVER) {
    showTooltip();
  }
};

// callback when the mouseout event is trigger on the tooltip (not the anchor)
const tooltipOut = () => {
  // if the display tooltip is by a mouseover event on the anchor
  if (tooltipEvent === MOUSE_EVENT_OVER) {
    timeoutHideTooltip();
  }
};

// generate the tooltip on the DOM
const generateTooltip = () => {
  elTooltip = document.createElement('div');
  elTooltipSpan = document.createElement('span');
  elTooltipContainer = elTooltip.cloneNode() as HTMLDivElement;
  elTooltipArrow = elTooltip.cloneNode() as HTMLDivElement;

  // add the link "learn more" at the end of the tooltip content
  elTooltipLink = document.createElement('a');
  const elTooltipLinkText = document.createTextNode(trans('common.learn-more'));
  elTooltipLink.addEventListener('click', trackTooltip);
  elTooltipLink.appendChild(elTooltipLinkText);
  elTooltipLink.classList.add('tooltip-link');
  elTooltipLink.setAttribute('rel', 'noopener');
  elTooltipLink.setAttribute('target', '_blank');

  elTooltip.className = TOOLTIP_CLASSNAME;
  elTooltipContainer.className = 'tooltip-content';
  elTooltipArrow.className = 'tooltip-arrow';

  elTooltipContainer.appendChild(elTooltipSpan);
  elTooltip.appendChild(elTooltipContainer);
  elTooltip.appendChild(elTooltipArrow);

  // attach all listener available to the tooltip itself
  elTooltip.addEventListener(MOUSE_EVENT_OVER, tooltipOver);
  elTooltip.addEventListener(MOUSE_EVENT_OUT, tooltipOut);

  if (elContentLayout) {
    elContentLayout.appendChild(elTooltip);
  } else {
    document.body.appendChild(elTooltip);
  }
};

const openTooltip: EventListenerOrEventListenerObject = evt => {
  // Set mouse out / over event behavior for the tooltip
  // the registered tooltip event defines the tooltip's behavior to the user's mouse action.
  // For example, if the tooltip is displayed on a mouseover, it will be active on its own mouseover
  // otherwise the mouseover will be muted
  tooltipEvent = MOUSE_EVENT_OVER;

  // generate the tooltip DOM once
  if (!elTooltip) {
    generateTooltip();
  }

  const anchor = evt.currentTarget as HTMLTooltipAnchorElement | null;

  // set the current anchor assign to the tooltip
  if (elTooltip) elTooltip.anchor = anchor;

  if (anchor && !anchor.tooltipObj.hoverOnce) {
    anchor.tooltipObj.hoverOnce = true;

    const {
      tooltipObj: { jan }
    } = anchor;

    eventEmitter.emit(TrackingEventNames.ON_HOVER_THEATER_TOOLTIP, { jan });
  }

  // display the tooltip
  showTooltip();
};

const init = () => {
  //
  // get anchors with attribute data-tooltip.
  // expect a JSON stringify with :
  //
  // {
  //    "content": "content to display",
  //    "event": "mouseover" || "click",
  //    "position: null || "top" || "right" || "bottom" || "left"
  // }
  //
  // example of data-attribute in html :
  // -----------------------------------
  // <div data-tooltip="{&quot;content&quot;:&quot;Lorem ipsum dolor sit amet&quot;,&quot;event&quot;:&quot;mouseover&quot;}"></div>
  //
  // in twig :
  // ---------
  // {% set data_tooltip = {
  //    content: 'Lorem ipsum dolor sit amet',
  //    event: 'mouseover'
  //  } %}
  //
  // <div data-tooltip="{{ data_tooltip|json_encode }}"></div>
  //
  const anchors =
    document.querySelectorAll<HTMLTooltipAnchorElement>('[data-tooltip]');
  let idx = 0;

  for (const anchor of anchors) {
    const dataTooltip = readAttribute<DataTooltip>(anchor, 'data-tooltip');
    if (typeof dataTooltip === 'object') {
      const { position, ...data } = dataTooltip;
      anchor.tooltipObj = data;

      // standardizes the position in the expected format
      // forces only the 5 values in lowercase: "top", "right", "bottom", "left", "auto"
      anchor.tooltipObj.position =
        !position || !/^(top|right|bottom|left)$/i.test(position)
          ? 'auto'
          : (position?.toLowerCase() as Position);
    }
    anchor.tooltipObj.id = idx;
    idx += 1;

    anchor.tooltipObj.url = readAttribute<string>(anchor, 'data-tooltip-url');

    const dataGa = readAttribute<Record<string, any>>(anchor, 'data-ga');
    anchor.tooltipObj.ga = typeof dataGa === 'object' ? dataGa : null;

    const jan = readAttribute<Record<string, string>>(anchor, 'data-jan');
    if (typeof jan === 'object') {
      anchor.tooltipObj.jan = jan;
    }

    // get eventType
    // determines the behavior of the tooltip at the user's mouse action on itself
    const eventType = anchor.tooltipObj.event || MOUSE_EVENT_OVER;

    // define behaviors by event type
    switch (eventType) {
      case MOUSE_EVENT_OVER:
        anchor.addEventListener(eventType, openTooltip);
        anchor.addEventListener(MOUSE_EVENT_OUT, timeoutHideTooltip);
        break;
      default:
        break;
    }

    window.addEventListener('resize', debounce(positionUpdate));
  }
};

export default init;
