import readAttribute from 'common/tools/dom/readAttribute';
import { isString } from 'common/types';

import { TheaterFromAttributes } from 'website/containers/showtimes/types';
import standalone from 'website/containers/showtimes/utils/standalone';

/* GLOBAL VARS */
let anchorsParent;

/* EXECUTION */
const init = async () => {
  anchorsParent = document.getElementById('theaterpage-showtimes-premiere-ui');
  if (!anchorsParent) {
    return;
  }

  const theater = readAttribute<TheaterFromAttributes, undefined>(
    anchorsParent,
    'data-theater'
  );

  if (!theater || isString(theater)) {
    return;
  }

  const anchors = anchorsParent.getElementsByClassName('showtimes-anchor');

  for (const item of anchors) {
    standalone(item);
  }
};

export default init;
