import React from 'react';

import { ShowtimeFilterButtonTracking } from 'common/constants/trackingEventsNames';

import FilterButton from 'website/components/showtimes/FilterButton';
import FilterDropdown from 'website/components/showtimes/FilterDropdown';
import {
  FilterRewritten,
  OnFilterChange
} from 'website/components/showtimes/PageFilters';

type FilterDropdownSimpleProps = {
  disabled?: boolean;
  filterModel?: FilterRewritten;
  onFilterChange: OnFilterChange;
  tracking: Omit<ShowtimeFilterButtonTracking, 'filterName'>;
};

const FilterDropdownSimple = ({
  disabled,
  filterModel,
  onFilterChange,
  tracking
}: FilterDropdownSimpleProps) => {
  const computeExtraProps = () => {
    let activeItemCount = 0;
    let unavailableCount = 0;

    const firstItemLabel = (filterModel?.values.find(item => item.active) || {})
      .label;

    filterModel?.values.forEach(item => {
      if (item.active) {
        activeItemCount += 1;
      }
      if (item.unavailable) {
        unavailableCount += 1;
      }
    });

    const noItems =
      filterModel?.values.length === 0 ||
      filterModel?.values.length === unavailableCount ||
      disabled;

    return { activeItemCount, firstItemLabel, noItems };
  };

  return (
    filterModel && (
      <FilterDropdown
        computeExtraProps={computeExtraProps}
        items={filterModel?.values}
        nullLabel={filterModel?.label}
        itemLength={filterModel?.values.length ?? 0}
      >
        {items =>
          items?.map(item => (
            <FilterButton
              active={item.active}
              disabled={item.unavailable}
              extraClasses="showtime-filter-option"
              key={item.key}
              label={item.label ?? ''}
              onClick={onFilterChange.bind(null, item.originalKey, item.key)}
              tracking={{
                filterName: item.translation ? item.translation.tag : item.key,
                ...tracking
              }}
            />
          ))
        }
      </FilterDropdown>
    )
  );
};

export default FilterDropdownSimple;
