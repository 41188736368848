import classNames from 'classnames';
import React from 'react';

import { TECHNOS_PREMIUM } from 'common/configuration/constants';
import { SHOWTIMES_EXPERIENCES_ICONS_CLASSES } from 'common/constants/Showtimes';
import {
  ShowtimeFilterButtonTracking,
  TrackingEventNames
} from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';

type FilterButtonProps = {
  active?: boolean;
  disabled?: boolean;
  extraClasses?: string;
  label?: string;
  onClick: () => void;
  tracking: ShowtimeFilterButtonTracking;
};

type LabelCoponentProps = { label?: string };

const getTechnoPremium = (label: string) =>
  TECHNOS_PREMIUM instanceof Object &&
  Object.values(TECHNOS_PREMIUM).find(techno => techno.translation === label);

const FilterButton = ({
  active,
  disabled,
  onClick,
  tracking,
  extraClasses,
  label
}: FilterButtonProps) => {
  const handleTracking = () => {
    eventEmitter.emit(
      TrackingEventNames.TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK,
      tracking
    );
  };
  const handleClick = () => {
    onClick();

    handleTracking();
  };

  const showtimeFilterButtonClass = classNames(
    'showtime-filter-button',
    {
      active,
      disabled
    },
    extraClasses
  );

  const LabelComponent = ({ label }: LabelCoponentProps) => {
    const technoPremium = label ? getTechnoPremium(label) : null;

    return technoPremium &&
      SHOWTIMES_EXPERIENCES_ICONS_CLASSES[technoPremium.tag] ? (
      <div
        className={classNames(
          'square',
          'icon',
          `icon-${SHOWTIMES_EXPERIENCES_ICONS_CLASSES[technoPremium.tag]}`
        )}
      ></div>
    ) : (
      label
    );
  };

  return (
    <label onClick={handleClick} className={showtimeFilterButtonClass}>
      <LabelComponent label={label} />
    </label>
  );
};

export default FilterButton;
