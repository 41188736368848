import { Genre, Maybe } from 'types/graphql-api.generated';

import { BOOKING, SHOWTIMES_VERSION } from 'common/constants/Showtimes';
import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';
import slugify from 'common/tools/string/slugify';
import trans from 'common/tools/translations/trans';
import { isString } from 'common/types';

import { MovieFromRequest } from 'website/containers/showtimes/types';
import {
  Filter,
  FormatedFilters,
  RequestPayload,
  RequestShowtimesData,
  RequestShowtimesFacets,
  RouteParam,
  ShowtimesFiltersFacets
} from 'website/containers/showtimes/utils/types';

const getFilters = (filters: FormatedFilters): string[] => {
  return filters.flatMap(filter =>
    Object.values(filter).flatMap(value =>
      Array.isArray(value) ? value.filter(isString) : []
    )
  );
};

const buildHash = (
  routeParam: Pick<RouteParam, 'day' | 'page'>,
  payload: Pick<RequestPayload, 'filters'>
) => {
  let hash = '#';

  if (routeParam.day) {
    hash += `shwt_date=${routeParam.day}`;
  }

  if (routeParam.page !== 1) {
    hash += `&page=${routeParam.page}`;
  }

  const filters = getFilters(payload.filters ?? []);

  if (filters.length) {
    hash += `&filters=${filters.map(key => slugify(key)).join('+')}`;
  }

  return hash;
};

export const addToHistory = (
  routeParam: Pick<RouteParam, 'day' | 'page'> = {},
  payload: Pick<RequestPayload, 'filters'> = {}
) => {
  const hash = buildHash(routeParam, payload);
  window.history.pushState(payload, '', hash);
};

export const requestShowtimes = async <
  T extends RequestShowtimesData,
  P extends { [key: string]: any },
  R = Record<string, any>
>(
  routeName: string,
  routeParam: R,
  payload?: P
) => {
  const url = path(routeName, { ...(routeParam ?? {}) });

  return request<T, P>(url, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    method: 'POST',
    payload: payload
  });
};

export const handlePagerHref = (
  routeName: string,
  pageNumber: number,
  payload: RequestPayload,
  routeParam: RouteParam = {}
) => {
  const modifiedRouteParam = { ...routeParam };
  let param = {};
  if (modifiedRouteParam.movie && modifiedRouteParam.localization) {
    param = {
      movie: modifiedRouteParam.movie,
      localization: modifiedRouteParam.localization
    };
  }

  if (modifiedRouteParam.idTheater) {
    param = {
      idTheater: modifiedRouteParam.idTheater
    };
  }

  return `${path(routeName, param)}${buildHash({ ...modifiedRouteParam, page: pageNumber }, payload)}`;
};

/**
 * This function will toggle the active state in the filter object for the given entityGroup and entityKey
 */
export const handleShowtimeFilterChange = (
  filters: Filter[] | null | undefined,
  entityGroup: string,
  entityKey: string
) => {
  const filterGroup = filters?.find(filter => filter.name === entityGroup);

  const filterEntity = filterGroup?.values?.find(
    entity => entity?.key === entityKey
  );

  if (filterEntity) {
    filterEntity.active = !filterEntity.active;
  }

  return { filters };
};

/**
 * Format filters to satisfy backend API
 * Entry : {name: "showtimes.version", values: [{key: "Dubbed", version: "DUBBED"}, {key: "Original", version: "ORIGINAL"}]}
 * Output : [{showtimes.version: ["ORIGINAL"]}] if version original is selected
 */
export const formatForApi = (
  filters?: Filter[] | null
): FormatedFilters | undefined =>
  filters?.reduce((accumulator: FormatedFilters, filter) => {
    const values = filter.values ?? [];
    const name = filter.name ?? '';
    const activeValues = values
      .filter(value => value?.active)
      .map(value => {
        if (name === SHOWTIMES_VERSION) {
          return value?.version;
        }
        if (name === BOOKING) {
          return value?.key;
        }
        return value?.translation?.tag;
      });
    if (activeValues.length) accumulator.push({ [name]: activeValues });
    return accumulator;
  }, []);

/**
 * This function will merge apiResponseFilters data into originalFilters data.
 * It will mark any filter not in api response as "unavailable".
 * This is mandatory because the API only returns "available filters" without keeping track of currently active filters.
 * We have to keep the original filters and merge the value of them with the return of the api.
 */
export const updateOriginalFilters = (
  originalFilters?: Filter[] | null,
  apiResponseFilters?: Filter[] | null
) =>
  originalFilters?.map(originalFilter => {
    // loop on original filters
    // for each value ...
    const values = originalFilter.values?.map(baseValues => {
      // ... we set the status of the button to unavailable
      const obj = { ...baseValues, unavailable: true };

      // then we look for data available in the new filters
      const apiResponseFilter = apiResponseFilters?.find(
        item => item.name === originalFilter.name
      );

      if (
        apiResponseFilter &&
        apiResponseFilter.values?.find(item => item?.key === baseValues?.key)
      ) {
        // Marking the filter as available
        obj.unavailable = false;
      }

      return obj;
    });

    return { name: originalFilter.name, values: values };
  });

/**
 * We need to set Booking filter on a specific format to match the other facets.
 */
export const modifyBookingFilter = (filters?: RequestShowtimesFacets) => {
  if (!filters) {
    return null;
  }

  // add booking data
  const bookingFilter = filters.facets?.find(item => item?.name === BOOKING);

  if (bookingFilter) {
    bookingFilter.values = [
      { key: BOOKING, label: trans('showtimes.filters.booking') }
    ];
  }

  return filters;
};

/**
 * We disable the version filter if there is only one option in it on the application start for UX purpose.
 */
export const movieHasMultipleVersion = (filters?: ShowtimesFiltersFacets[]) => {
  if (!filters) {
    return null;
  }

  // add booking data
  const versionFilter = filters?.find(item => item?.name === SHOWTIMES_VERSION);

  return !!(versionFilter && (versionFilter.values?.length ?? 0) > 1);
};

/**
 * We need to create an array of disabled dates
 */
export const getDisabledDates = (
  rollerDates?: Maybe<string[]>,
  showtimesDates?: Maybe<string[]>
) =>
  rollerDates?.reduce((accumulator: string[], date) => {
    if (!showtimesDates?.find(item => item === date)) {
      accumulator.push(date);
    }

    return accumulator;
  }, []);

export const movieForTracking = (movie?: MovieFromRequest) => ({
  ...(movie ?? {}),
  genres:
    movie?.genres?.reduce<Array<Genre>>(
      (acc, genre) => (genre.tag ? [...acc, genre.tag] : acc),
      []
    ) ?? null
});

export const getUrlHashParams = (hash: string) => {
  const regex = new RegExp(`${hash}([^&]*)`);
  const match = window?.location?.hash?.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return null;
};

export const getActiveFilters = (
  filtersFromUrl?: string[],
  filtersFromRequest?: RequestShowtimesFacets | null
) =>
  filtersFromUrl?.length
    ? filtersFromRequest?.facets?.map(filter => {
        filter.values?.forEach(value => {
          if (
            (filter.name === SHOWTIMES_VERSION &&
              value?.version &&
              filtersFromUrl.includes(slugify(value.version))) ||
            (filter.name === BOOKING &&
              value?.key &&
              filtersFromUrl.includes(slugify(value.key))) ||
            (value?.translation?.tag &&
              filtersFromUrl.includes(slugify(value.translation.tag)))
          ) {
            value.active = true;
          }
        });

        return filter;
      })
    : filtersFromRequest?.facets;
