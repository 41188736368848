import React from 'react';

import formatDate, { DATE_FORMATS } from 'common/tools/date/format';
import trans from 'common/tools/translations/trans';

import { RequestShowtimesData } from 'website/containers/showtimes/utils/types';

type ShowtimesTitleProps = {
  data?: RequestShowtimesData;
};
const ShowtimesTitle = ({ data }: ShowtimesTitleProps) =>
  !data?.error &&
  data?.message &&
  data?.messageParam?.day && (
    <div className="showtime-result-title">
      {trans(data.message, {
        day: formatDate(new Date(data.messageParam.day), DATE_FORMATS.LONG)
      })}
    </div>
  );

export default ShowtimesTitle;
