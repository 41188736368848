import { useEffect, useRef } from 'react';

/**
 * React hook to save a value in a render and access it in the next render (as previous value)
 * @param {*} value the value to save
 * @returns {*} the previous value
 */
const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
