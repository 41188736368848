import classNames from 'classnames';
import React from 'react';

import { Showtime } from 'types/graphql-api.generated';

import ShowtimesHour from 'common/components/Showtimes/ShowtimesHour';
import { TheaterReservationTracking } from 'common/constants/trackingEventsNames';
import formatDate, { DATE_FORMATS } from 'common/tools/date/format';
import { dateProcessingFromAString } from 'common/tools/date/showtime';
import getTicketingUrl from 'common/tools/showtime/getTicketingUrl';

export type OpenModalShowtimes = (
  showtimeId: number,
  fallback: (() => void) | null,
  tracking: TheaterReservationTracking
) => void;

type PartialShowtime = Pick<
  Showtime,
  | '__typename'
  | 'comfort'
  | 'data'
  | 'diffusionVersion'
  | 'experience'
  | 'internalId'
  | 'picture'
  | 'projection'
  | 'service'
  | 'sound'
  | 'startsAt'
  | 'tags'
  | 'timeBeforeStart'
>;

type ShowtimesVersionProps = {
  hideDate?: boolean;
  openModal?: OpenModalShowtimes;
  showtimesHours: Array<PartialShowtime>;
  showtimesLabel: string;
  tracking: Omit<TheaterReservationTracking, 'showtime'>;
};

const ShowtimesVersion = ({
  hideDate,
  openModal,
  showtimesHours,
  showtimesLabel,
  tracking
}: ShowtimesVersionProps) => {
  if (!showtimesHours.length) {
    return null;
  }

  const showtimesUi = (
    <>
      {showtimesHours.map((showtime, index) => {
        const startsAtData = dateProcessingFromAString(showtime.startsAt);

        // use index for a key is a bad practice, but showtime hour is too messy and always trigger some key error
        const key = `${showtime.internalId}_${index}_${showtime.startsAt}`;

        const isPast = startsAtData.isPast;

        const ticketingUrl = getTicketingUrl(showtime?.data?.ticketing);
        const className = classNames(
          { past: isPast },
          { bookable: ticketingUrl && !isPast }
        );

        return (
          <ShowtimesHour
            ticketingUrl={ticketingUrl}
            className={className}
            hour={startsAtData.hour}
            experiences={showtime.tags}
            key={key}
            openModal={openModal?.bind(null, showtime.internalId)}
            tracking={{
              ...tracking,
              showtime
            }}
          />
        );
      })}
    </>
  );

  const text = hideDate
    ? showtimesLabel
    : `${formatDate(new Date(showtimesHours[0].startsAt), {
        timeZone: 'UTC',
        ...DATE_FORMATS.LONG
      })} - ${showtimesLabel}`;

  return (
    <div className="showtimes-version">
      <div className="text">{text}</div>
      <div className="hours">{showtimesUi}</div>
    </div>
  );
};

export default ShowtimesVersion;
