import loadScript from 'load-script';

let loaded = false;
let loading = false;

const URL = 'https://acceslibre.beta.gouv.fr/static/js/widget.js';

export default (force?: boolean) => {
  if (!loading) {
    loading = true;
    const widgetA11yContainer = document.querySelectorAll(
      '[id^="widget-a11y-container"]'
    );

    if (!loaded && (widgetA11yContainer.length || force)) {
      loadScript(URL, {}, () => {
        loaded = true;
        loading = false;
      });
    }
  }
};
