import React, { useMemo } from 'react';

import { Maybe } from 'types/graphql-api.generated';

import withMQState from 'common/components/withMQState';
import {
  BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES,
  TECHNOS_PREMIUM
} from 'common/configuration/constants';
import { MQ_MEDIUM } from 'common/constants/MediaQueries';
import {
  BOOKING,
  LOYALTY_CARDS,
  SHOWTIMES_COMFORT,
  SHOWTIMES_EXPERIENCE,
  SHOWTIMES_PICTURE,
  SHOWTIMES_PROJECTION,
  SHOWTIMES_SOUND,
  SHOWTIMES_VERSION
} from 'common/constants/Showtimes';
import { ShowtimeFilterButtonTracking } from 'common/constants/trackingEventsNames';
import trans from 'common/tools/translations/trans';

import FilterButton from 'website/components/showtimes/FilterButton';
import FilterDropdownSeeMore from 'website/components/showtimes/FilterDropdownSeeMore';
import FilterDropdownSimple from 'website/components/showtimes/FilterDropdownSimple';
import FilterDropdownsDesktop from 'website/components/showtimes/FilterDropdownsDesktop';
import { Filter, FilterValue } from 'website/containers/showtimes/utils/types';

export type OnFilterChange = (
  entityGroup: string,
  entityKey?: string | null
) => void;

type PageFiltersProps = {
  filters: Filter[];
  mqState: number;
  onFilterChange: OnFilterChange;
  seeMore?: boolean;
  versionDisabled: boolean;
  tracking: Omit<ShowtimeFilterButtonTracking, 'filterName' | 'eventLabel'>;
};

export type FilterItem = FilterValue & {
  originalKey: string;
  unavailable?: boolean;
};

export type FilterRewritten = { label: string; values: FilterItem[] };

export type FiltersRewritten = Record<string, FilterRewritten>;

const FILTER_NAMES = [
  BOOKING,
  LOYALTY_CARDS,
  SHOWTIMES_PROJECTION,
  SHOWTIMES_VERSION,
  SHOWTIMES_PICTURE,
  SHOWTIMES_SOUND,
  SHOWTIMES_EXPERIENCE,
  SHOWTIMES_COMFORT
];

const EXPERIENCE_FILTER_NAMES = [
  SHOWTIMES_COMFORT,
  SHOWTIMES_EXPERIENCE,
  SHOWTIMES_PICTURE,
  SHOWTIMES_PROJECTION,
  SHOWTIMES_SOUND
];

const isVersionFilter = (filterName: string) =>
  filterName === SHOWTIMES_VERSION;

const isLocalOrDubbed = (key: string) => /^(Local|Dubbed)$/i.test(key);

const shouldMergeLocalAndDubbedVersion = (item: Maybe<FilterValue>) =>
  item?.key &&
  BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES &&
  isLocalOrDubbed(item.key);

/**
 * return translated label for filter version
 */
const getTranslatedFilterLabel = (
  filterName: string,
  item: Maybe<FilterValue>
) => {
  if (!item) return undefined;

  if (!isVersionFilter(filterName)) {
    return item.translation ? item.translation.name : item.key;
  }

  if (shouldMergeLocalAndDubbedVersion(item)) {
    return trans('showtimes.version.local');
  }

  return item.key
    ? trans(`showtimes.version.${item.key.toLowerCase()}`)
    : undefined;
};

const initializeFilterGroupIfNotExists = (
  filtersRewritten: FiltersRewritten,
  mergedName: string
) => {
  if (!filtersRewritten[mergedName]) {
    filtersRewritten[mergedName] = {
      label: trans(mergedName),
      values: []
    };
  }
};

const shouldAddFilterValue = (
  item: Maybe<FilterValue>,
  localVersionComponentCreated: boolean
) =>
  item?.key &&
  (!BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES ||
    !isLocalOrDubbed(item.key) ||
    !localVersionComponentCreated);

const addFilterValue = (
  filtersRewritten: FiltersRewritten,
  mergedName: string,
  filterName: string,
  item: Maybe<FilterValue>
) => {
  filtersRewritten[mergedName].values.push({
    originalKey: filterName,
    label: getTranslatedFilterLabel(filterName, item) ?? undefined,
    ...item
  });
};

// Sorting function for premium technologies
const sortPremiumTechnologiesFirst = (item: FilterItem) => {
  if (
    TECHNOS_PREMIUM instanceof Object &&
    item?.translation?.tag &&
    TECHNOS_PREMIUM[item.translation.tag] &&
    TECHNOS_PREMIUM[item.translation.tag].premium
  ) {
    return -1;
  }
  return 0;
};

// Function to sort filters
const sortFilters = (filtersRewritten: FiltersRewritten) => {
  const sortedFilters: FiltersRewritten = {};

  // Use Object.entries to iterate over the object
  for (const [filterGroupKey, filterGroupValue] of Object.entries(
    filtersRewritten
  )) {
    const { values } = filterGroupValue;

    sortedFilters[filterGroupKey] = {
      ...filterGroupValue,
      values: values.sort(sortPremiumTechnologiesFirst)
    };
  }

  return sortedFilters;
};

const buildFilterDataModel = (
  filters: Filter[],
  filterName: string,
  mergedName: string,
  filtersRewritten: FiltersRewritten
) => {
  const filterGroup = filters.find(filter => filter.name === filterName);

  // don't display any filter if only one version is available
  // example: if all movies are in VF, the filter VF is useless
  if (
    !filterGroup ||
    ([SHOWTIMES_PROJECTION, SHOWTIMES_VERSION].includes(filterName) &&
      (filterGroup.values?.length ?? 0) <= 1)
  ) {
    return;
  }

  initializeFilterGroupIfNotExists(filtersRewritten, mergedName);

  let localVersionComponentCreated = false;

  filterGroup.values?.forEach(item => {
    if (shouldAddFilterValue(item, localVersionComponentCreated)) {
      addFilterValue(filtersRewritten, mergedName, filterName, item);
    }

    if (shouldMergeLocalAndDubbedVersion(item)) {
      localVersionComponentCreated = true;
    }
  });
};

const PageFilters = ({
  filters,
  mqState,
  onFilterChange,
  seeMore,
  versionDisabled,
  tracking
}: PageFiltersProps) => {
  const dataTracking = useMemo(
    () => ({ eventLabel: 'moviepage', ...tracking }),
    [tracking]
  );

  const buildBookingButtonComponent = (filtersRewritten: FiltersRewritten) => {
    if (!filtersRewritten[BOOKING]) {
      return null;
    }

    const filterValues = filtersRewritten[BOOKING].values;

    return filterValues.length && mqState === MQ_MEDIUM ? (
      <FilterButton
        active={filterValues[0].active}
        disabled={filterValues[0].unavailable}
        label={filterValues[0].label ?? ''}
        extraClasses="showtime-filter-display-button"
        onClick={onFilterChange.bind(null, BOOKING, BOOKING)}
        tracking={{ filterName: BOOKING, ...dataTracking }}
      />
    ) : null;
  };

  const rewriteFilters = () => {
    const filtersRewritten: FiltersRewritten = {};

    FILTER_NAMES.forEach(filterName => {
      if (EXPERIENCE_FILTER_NAMES.includes(filterName)) {
        // Merge Experience filters
        buildFilterDataModel(
          filters,
          filterName,
          SHOWTIMES_EXPERIENCE,
          filtersRewritten
        );
      } else {
        buildFilterDataModel(filters, filterName, filterName, filtersRewritten);
      }
    });

    return filtersRewritten;
  };

  const filtersRewritten = rewriteFilters();

  // display booking button only if available and for tablet layout
  const bookingButtonComponent = buildBookingButtonComponent(filtersRewritten);

  return (
    <div className="showtimes-filters showtimes-filters-bar">
      <FilterDropdownSimple
        disabled={versionDisabled}
        filterModel={filtersRewritten[SHOWTIMES_VERSION]}
        onFilterChange={onFilterChange}
        tracking={dataTracking}
      />
      <FilterDropdownsDesktop
        filters={sortFilters(filtersRewritten)}
        mqState={mqState}
        onFilterChange={onFilterChange}
        tracking={dataTracking}
        responsive={!!seeMore}
      />
      {bookingButtonComponent}
      {seeMore && (
        <FilterDropdownSeeMore
          filters={sortFilters(filtersRewritten)}
          mqState={mqState}
          onFilterChange={onFilterChange}
          tracking={dataTracking}
        />
      )}
    </div>
  );
};

export default withMQState(PageFilters);
