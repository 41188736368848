import { Maybe, ShowtimeVersion } from 'types/graphql-api.generated';

import { BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES } from 'common/configuration/constants';
import trans from 'common/tools/translations/trans';

const dubbedTrans = trans('showtimes.format', {
  name: trans('showtimes.version.dubbed')
});
const localTrans = trans('showtimes.format', {
  name: trans('showtimes.version.local')
});
const multipleTrans = trans('showtimes.format', {
  name: trans('showtimes.version.translated')
});
const originalTrans = trans('showtimes.format', {
  name: trans('showtimes.version.original')
});

const getVersionLabel = (version?: Maybe<ShowtimeVersion>) => {
  switch (version) {
    case ShowtimeVersion.Dubbed:
      return BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES
        ? multipleTrans
        : dubbedTrans;
      break;
    case ShowtimeVersion.Local:
      return BRAND_HAS_SAME_LOCAL_AND_DUBBED_SHOWTIMES
        ? multipleTrans
        : localTrans;
      break;
    case ShowtimeVersion.Original:
      return originalTrans;
      break;
    default:
      return '';
  }
};

export default getVersionLabel;
