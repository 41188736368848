import React from 'react';

import { MQ_SMALL } from 'common/constants/MediaQueries';
import { SHOWTIMES_EXPERIENCE } from 'common/constants/Showtimes';
import { ShowtimeFilterButtonTracking } from 'common/constants/trackingEventsNames';

import FilterDropdownSimple from 'website/components/showtimes/FilterDropdownSimple';
import {
  FiltersRewritten,
  OnFilterChange
} from 'website/components/showtimes/PageFilters';

type FilterDropdownsDesktopProps = {
  filters: FiltersRewritten;
  mqState: number;
  onFilterChange: OnFilterChange;
  tracking: Omit<ShowtimeFilterButtonTracking, 'filterName'>;
  responsive: boolean;
};

const FilterDropdownsDesktop = ({
  filters,
  mqState,
  onFilterChange,
  tracking,
  responsive
}: FilterDropdownsDesktopProps) =>
  !responsive || mqState > MQ_SMALL ? (
    <FilterDropdownSimple
      filterModel={filters[SHOWTIMES_EXPERIENCE]}
      onFilterChange={onFilterChange}
      tracking={tracking}
    />
  ) : null;

export default FilterDropdownsDesktop;
