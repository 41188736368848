import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import noop from 'common/tools/noop';
import trans from 'common/tools/translations/trans';

class Pager extends Component {
  renderPreviousLink() {
    const { currentPage, getPageUrl, onNavigationChange } = this.props;
    const linkClasses = classNames('button button-primary-full button-left', {
      'button-disabled': currentPage <= 1
    });
    return (
      <a
        className={linkClasses}
        href={getPageUrl(currentPage - 1)}
        onClick={onNavigationChange.bind(null, currentPage - 1)}
      >
        <i className="icon icon-left icon-arrow-left" />
        <span className="txt">{trans('previous')}</span>
      </a>
    );
  }

  renderNextLink() {
    const { currentPage, pageCount, getPageUrl, onNavigationChange } =
      this.props;
    const linkClasses = classNames('button button-primary-full button-right', {
      'button-disabled': currentPage === pageCount
    });
    return (
      <a
        className={linkClasses}
        href={getPageUrl(currentPage + 1)}
        onClick={onNavigationChange.bind(null, currentPage + 1)}
      >
        <span className="txt">{trans('next')}</span>
        <i className="icon icon-right icon-arrow-right" />
      </a>
    );
  }

  renderPages() {
    const pages = [];
    const {
      currentPage,
      includesFirst,
      includesLast,
      pageCount,
      getPageUrl,
      onNavigationChange
    } = this.props;

    let pageNumberItem = 1;
    for (pageNumberItem; pageNumberItem <= pageCount; pageNumberItem++) {
      pages.push(pageNumberItem);
    }

    const pagesLinks = [];
    if (!includesFirst) {
      pagesLinks.push(
        <a
          key="first"
          className="button item"
          href={getPageUrl(1)}
          onClick={onNavigationChange.bind(null, 1)}
        >
          1
        </a>
      );
      pagesLinks.push(
        <span key="first-separator" className="button">
          ...
        </span>
      );
    }
    pages.forEach(pageNumber => {
      if (pageNumber === currentPage) {
        pagesLinks.push(
          <span key={pageNumber} className="button item current-item">
            {pageNumber}
          </span>
        );
      } else {
        pagesLinks.push(
          <a
            key={pageNumber}
            className="button item"
            href={getPageUrl(pageNumber)}
            onClick={onNavigationChange.bind(null, pageNumber)}
          >
            {pageNumber}
          </a>
        );
      }
    });
    if (!includesLast) {
      pagesLinks.push(
        <span key="last-separator" className="button">
          ...
        </span>
      );
      pagesLinks.push(
        <a
          key="last"
          className="button item"
          href={getPageUrl(pageCount)}
          onClick={onNavigationChange.bind(null, pageCount)}
        >
          {pageCount}
        </a>
      );
    }
    return pagesLinks;
  }

  render() {
    const { pageCount } = this.props;
    if (pageCount <= 1) {
      // pageCount can be equal to 0 if there are no items
      return null;
    }
    return (
      <nav className="md pagination cf">
        {this.renderPreviousLink()}
        {this.renderNextLink()}
        <div className="pagination-item-holder">{this.renderPages()}</div>
      </nav>
    );
  }
}

Pager.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  includesFirst: PropTypes.bool.isRequired,
  includesLast: PropTypes.bool.isRequired,
  getPageUrl: PropTypes.func.isRequired,
  onNavigationChange: PropTypes.func
};

Pager.defaultProps = {
  onNavigationChange: noop
};

export default Pager;
